import styled from 'styled-components';

export const SectionTitle = styled.h2`
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 500;
  padding: 12px;
  margin: 20px 0;
  color: #525fff;
  font-weight: bold
`;

export const BannerImage = styled.img`
  margin-top: 20px;
  width: 100%;
  height: 300px
`;

export const LinkA = styled.a`
  font-size: 20px;
  text-decoration: underline !important;
`;

export const CourseList = styled.ul`
  font-size: 19px;
  margin: 10px 0;
  li {
    margin: 15px 0;
  }
`;

export const Text = styled.p`
  font-size: 17px;
`
